<template lang="pug">
  tr.matchings-item
    td.item-name
      span(:class="{ inactive: !shop.sync }")
        | {{ shop.name }}
    td.item-name-dropdown
      AppDropdown(
        checkbox
        z-index="103"
        batch-select
        multiple
        allow-empty
        searchable
        close-on-select
        draggable-selected
        :placeholder="$t('shops_car_class_matching.select_car_class')"
        :class="{ inactive: !shop.sync }"
        :value="selectedCarClasses"
        :items="shop.available_car_classes"
        @select="selectCarClass"
      )
    td.sticky-col.selected-quantity(:class="{ updated: shop._updated }")
      span {{ `${selectedCarClasses.length}/${shop.available_car_classes.length}` }}
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { intersectionBy } from "lodash-es"

  export default {
    props: {
      shop: Object,
      index: Number
    },

    mixins: [withPermissions],

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      selectedCarClasses({ shop }) {
        return intersectionBy(shop.car_classes, shop.available_car_classes, "id")
      }
    },

    methods: {
      selectCarClass(value) {
        const item = { ...this.shop, car_classes: value }

        this.$emit("select-car-class", { item, index: this.index })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-item
    td
      +matchings-item-td

      &.item-name-dropdown
        width: 47%

      &.item-name
        width: 47%

      &.selected-quantity
        width: 6%
      &.updated
        color: $default-green
        font-weight: 700
</style>
